$nightBtn: rgb(242, 231, 229);
$dayBtn: #f8bc42;

#root {
    transition: background-color .4s ease;
}

.dayNight {
    
    cursor: pointer;

    input {
        display: none;
        & + div {
            border-radius: 50%;
            width: 36px;
            height: 36px;
            position: relative;
            box-shadow: inset 16px -8px 0 0 $nightBtn;
            transform: scale(1) rotate(-2deg);
            transition: box-shadow .5s ease 0s, transform .4s ease .1s;
            &:before {
                content: '';
                width: inherit;
                height: inherit;
                border-radius: inherit;
                position: absolute;
                left: 0;
                top: 0;
                transition: background .3s ease;
            }
            &:after {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin: -4px 0 0 -4px;
                position: absolute;
                top: 50%;
                left: 50%;
                box-shadow: 0 -23px 0 $dayBtn, 0 23px 0 $dayBtn, 23px 0 0 $dayBtn, -23px 0 0 $dayBtn, 15px 15px 0 $dayBtn, -15px 15px 0 $dayBtn, 15px -15px 0 $dayBtn, -15px -15px 0 $dayBtn;
                transform: scale(0);
                transition: all .3s ease;
            }
        }
        &:checked + div {
            box-shadow: inset 32px -32px 0 0 $nightBtn;
            transform: scale(.5) rotate(0deg);
            transition: transform .3s ease .1s, box-shadow .2s ease 0s;
            &:before {
                background: $dayBtn;
                transition: background .3s ease .1s;
            }
            &:after {
                transform: scale(1.5);
                transition: transform .5s ease .15s;
            }
        }
    }
}