@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Vulf Sans";
        font-weight: 600;
        src: url('./fonts/Vulf_Sans-Black.woff') format('woff'), url('./fonts/Vulf_Sans-Black.woff2') format('woff2')
    }
    @font-face {
        font-family: "Vulf Sans";
        font-weight: 500;
        style: italic;
        src: url('./fonts/Vulf_Sans-Italic.woff') format('woff'), url('./fonts/Vulf_Sans-Italic.woff2') format('woff2')
    }
    @font-face {
        font-family: "Vulf Sans";
        font-weight: 800;
        src: url('./fonts/Vulf_Sans-Bold.woff') format('woff'), url('./fonts/Vulf_Sans-Bold.woff2') format('woff2')
    }

    html {
        font-family: 'Vulf Sans', serif;
    }
}